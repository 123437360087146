export default {
  activeProductName: (state) => state.config.productName,
  config: (state) => state.config,
  gameState: (state) => state.gameState,
  streamConfig: (state) => state.streamConfig,
  streamToken: (state) => state.streamConfig.token,
  labelColors: (state) => state.config.racerLabelColors,
  racers: (state) => state.config.numberOfContestants.value,
  reverseForecast: (state) => state.config.reverseForecast,
  forecastOdds: (state) => state.forecastOdds,
  reverseForecastOdds: (state) => state.reverseForecastOdds,
  reverseForecastCurrentRound: (state) => state.forecastCurrentRound,
  raceCardOdds: (state) => state.raceCardOdds,
  headToHeadOdds: (state) => state.headToHeadOdds,
  offer: (state) => state.offer,
  disabledRound: (state) => state.disabledRound,
  currentRound: (state) => state.offerCurrentRound,
  isPayinInProgress: (state) => state.isPayinInProgress,
  expiredBets: (state) => state.expiredBets,
  isIsonis: (state) => state.isonisCountryList.indexOf(state.config.taxAuthority) > -1,
  visualizationUrl: (state) => state.visualizationUrl,
  disabledRoundBySocket: (state) => state.disabledRoundBySocket,
  userUuid: (state) => state.userUuid,
  isMobile: (state) => state.isMobile,
  isBoostActive: (state) => state.config.ui.config?.boost?.enabled || false,
  jackpotState: (state) => state.jackpotState,
  getSetStateMessage: (state) => state.setStateData,
  isSetStateTransfered: (state) => state.isStateMesageTrasfered,
  reverseForecastActive: (state) => state.reverseForecastActive,
  isTerminal: (state) => state.isTerminal,
  nextRoundTime: (state) => state.nextRoundTime,
  inProgressRound: (state) => state.inProgressRound,
  terminalUserData: (state) => state.terminalUserData,
  currentScheduleRound: (state) => state.currentScheduleRound,
  ticketHistoryTerminal: (state) => state.ticketHistoryTerminal,
  ticketDetailsData: (state) => state.ticketDetailsData,
  loaded: (state) => state.loaded,
  activeTerminalLayout: (state) => state.activeTerminalLayout,
  isTerminalMouseCursorEnabled: (state) => state.terminalMouseCursorEnabled,
  allBettingDisabled: (state) => state.allBettingDisabled,
  isFreeBetMode: (state) => state.isFreeBetMode,
  userIsOperator: (state) => state.terminalUserData.userType === 'OPERATOR' || state.terminalUserData.type === 'OPERATOR',
  ajsDisabled: (state) => state.config?.ui?.config?.ajsDisabled ?? false,
};
