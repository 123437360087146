<template>
  <div class="modal-wrapper">
    <Modal :fullScreen="isMobile"
           :showFooter="true"
           class="flex"
           width="800px"
           height="500px"
           v-if="showModal"
           @closeModal="closeDetails"
           @closeModalOutside="closeDetails('outside')"
           ref="modal">
      <template #header>
        <div class="flex-center">
          <span> {{ productName }} </span>
          <i class="icon icon-close-a"
             @click="closeDetails" />
        </div>
      </template>
      <template #body>
        <TicketsHistory :locale="config.locale" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { Modal, TicketsHistory, eventBus } from '@nsftx/games-sdk-js';
import { mapGetters } from 'vuex';

export default {
  name: 'TicketHistory',
  components: {
    Modal,
    TicketsHistory,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'config',
      'translations',
    ]),
    isMobile() {
      return window.innerWidth < 660;
    },
    productName() {
      return this.translations[this.config.productNameLabel];
    },
  },
  methods: {
    closeDetails(option) {
      if (option === 'outside') {
        this.showModal = false;
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.showModal = false;
        }, 500);
      }
    },
  },
  mounted() {
    eventBus.$on('openTicketHistory', () => {
      this.showModal = true;
    });
    eventBus.$on('closeTicketHistory', () => {
      this.showModal = false;
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-container {
  height: 18px;
}
::v-deep .modal-back {
  .modal {
    overflow: visible;
  }
}
::v-deep .modal {
  background-color: var(--background-1) !important;
  padding-bottom: 16px !important;
  .modal-header {
    .flex-center {
      background-color: var(--background-1);
    }
  }
}
::v-deep .tickets-list-wrapper {
  max-height: 350px !important;
  overflow-y: scroll;
  scrollbar-width: none;
  padding-bottom: 40px;
}
::v-deep .ticket-details-modal {
  .modal {
    background-color: var(--card) !important;
    .modal-header {
      padding-bottom: 24px !important;
    }
  }
}
::v-deep .show-modal {
  color: var(--text-primary-1);
}
.modal-header {
  div {
    text-align: center;
    background-color: var(--background-1);
    span {
      color: var(--text-primary-1);
      font-size: 24px;
      margin-left: auto;
    }
    .icon {
      color: var(--text-primary-1);
      cursor: pointer;
      margin-left: auto;
      padding-right: 4px;
    }
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 420px) {
  .modal-header {
    padding-bottom: 0px;
    div {
      span {
        margin-left: 0px;
        font-size: 14px;
      }
    }
  }
  ::v-deep .tickets-list-wrapper {
    max-height: 100vh !important;
    height: 80vh;
    overflow-y: scroll;
    padding-bottom: 50px;
  }
}
::v-deep .ticket-details-modal {
  .modal {
    padding: 0 !important;
    .modal-header {
      padding-left: 16px;
    }
  }
}
</style>
