export default {
  SET_STREAM_TOKEN: 'SET_STREAM_TOKEN',
  SET_CONFIG: 'SET_CONFIG',
  SET_GAME_STATE: 'SET_GAME_STATE',
  RESET_FORECAST: 'RESET_FORECAST',
  SET_CURRENT_ROUND: 'SET_CURRENT_ROUND',
  UPDATE_REVERSE_BET: 'UPDATE_REVERSE_BET',
  SET_OFFER: 'SET_OFFER',
  SET_H2H_COMBS: 'SET_H2H_COMBS',
  CREATE_FORECAST_SCHEMA: 'CREATE_FORECAST_SCHEMA',
  PARSE_FORECAST_ODDS: 'PARSE_FORECAST_ODDS',
  PARSE_REVERSE_FORECAST_ODDS: 'PARSE_REVERSE_FORECAST_ODDS',
  PARSE_RACECARD_ODDS: 'PARSE_RACECARD_ODDS',
  PARSE_HEADTOHEAD_ODDS: 'PARSE_HEADTOHEAD_ODDS',
  STOP_BETTING: 'STOP_BETTING',
  SET_PLAYER: 'SET_PLAYER',
  SET_PAYIN_PROGRESS: 'SET_PAYIN_PROGRESS',
  SET_EXPIRED_BETS: 'SET_EXPIRED_ROUNDS',
  SET_DISABLED_ROUND_BY_SOCKET: 'SET_DISABLED_ROUND_BY_SOCKET',
  SET_USER_UUID: 'SET_USER_UUID',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_JACKPOT_STATE: 'SET_JACKPOT_STATE',
  SET_SET_STATE_MESSAGE: 'SET_SET_STATE_MESSAGE',
  TRANSFER_STATE_MESSAGE: 'TRANSFER_STATE_MESSAGE',
  SET_IS_REVERSE_FORECAST_ACTIVE: 'SET_IS_REVERSE_FORECAST_ACTIVE',
  SET_IS_TERMINAL: 'SET_IS_TERMINAL',
  SET_NEXT_ROUND_TIME: 'SET_NEXT_ROUND_TIME',
  SET_IN_PROGRESS_ROUND: 'SET_IN_PROGRESS_ROUND',
  SET_TERMINAL_USER_DATA: 'SET_TERMINAL_USER_DATA',
  SET_TERMINAL: 'SET_TERMINAL',
  SET_CURRENT_SCHEDULE_ROUND: 'SET_CURRENT_SCHEDULE_ROUND',
  SET_TICKET_HISTORY_TERMINAL: 'SET_TICKET_HISTORY_TERMINAL',
  SET_TICKET_DETAILS_DATA: 'SET_TICKET_DETAILS_DATA',
  SET_LOADED: 'SET_LOADED',
  SET_ACTIVE_TERMINAL_LAYOUT: 'SET_ACTIVE_TERMINAL_LAYOUT',
  SET_TERMINAL_MOUSE_CURSOR_ENABLED: 'SET_TERMINAL_MOUSE_CURSOR_ENABLED',
  DISABLE_ALL_BETTING: 'DISABLE_ALL_BETTING',
};
