import axios from 'axios';
import { toLower } from 'lodash';
import index from './interceptors';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_SEVEN,
  timeout: 10000,
});
const httpAjs = axios.create({
  timeout: 10000,
});
http.interceptors.request.use(...index.request);
http.interceptors.response.use(...index.response);

export default {
  async getStreamToken(config, data) {
    const response = await http.post('/web/streaming/generate-token.json', {
      stream: data.plugin.data.stream,
      pageUrl: data.pageUrl,
    }, config);

    return response.data;
  },
  async getJackpot(config) {
    const channelId = toLower(config.applicationName) === 'terminal' ? 2 : 3;

    const response = await httpAjs.get('/open/nonstandalone', {
      baseURL: process.env.VUE_APP_GAMES_AJS_API,
      data: {
        isJackpotRequest: true,
      },
      params: {
        account: config.tenantId,
        game: config.productId,
        channel: channelId,
        location: toLower(config.applicationName),
        betshop: toLower(config.applicationName),
      },
    });
    return response.data;
  },
};
