// import store from '@/store';
import stream from '@nsftx/games-sdk-js/src/utility/stream';

export default {
  request: [
    (config) => {
      const streamCompanyUuid = stream.getStreamUuid(config.environment, config.tenantId)
        || config.tenantId;
      const transformedConfig = config;
      transformedConfig.headers['HTTP-X-SEVEN-CLUB-UUID'] = streamCompanyUuid;
      transformedConfig.headers['HTTP-X-NAB-DP'] = config.applicationName;
      transformedConfig.headers['SEVEN-LOCALE'] = config.locale;

      return transformedConfig;
    },
    (error) => Promise.reject(error),
  ],
  response: [
    (response) => {
      const { player } = '';
      const token = response.headers['access-token'];

      /*
       On each ticket succes we are refreshing
       Seven auth token.
       */
      if (player && player.auth && token) {
        player.auth.token = token;
      }

      return response;
    },
    (error) => Promise.reject(error),
  ],
};
