export default {
  config: {},
  gameState: {},
  forecastOdds: {},
  reverseForecastOdds: {},
  raceCardOdds: {},
  headToHeadOdds: {},
  headToHeadCombinations: [],
  headToHeadRacers: [1, 2, 3, 4],
  reverseForecast: null,
  offerCurrentRound: null,
  isPayinInProgress: false,
  offer: [],
  streamConfig: {
    token: null,
    origin: {
      url: '://stream-staging.7platform.live',
    },
    enabled: true,
    data: {
      auth: true,
      resolution: {
        low: '240p',
        high: '480p',
      },
      sources: [
        {
          protocol: 'rtmp',
          type: 'rtmp/mp4',
        },
        {
          protocol: 'https',
          extension: '.m3u8',
          type: 'application/x-mpegURL',
        },
        {
          protocol: 'https',
          extension: '.mpd',
          type: 'application/dash+xml',
        },
      ],
      ratio: '16:9',
    },
  },
  visualizationUrl: null,
  disabledRound: 0,
  disabledRoundBySocket: null,
  expiredBets: false,
  isonisCountryList: ['MNE'],
  userUuid: null,
  isMobile: false,
  jackpotState: undefined,
  setStateData: null,
  isStateMesageTrasfered: false,
  reverseForecastActive: false,
  isTerminal: false,
  nextRoundTime: null,
  inProgressRound: '',
  terminalUserData: null,
  currentScheduleRound: null,
  ticketHistoryTerminal: [],
  ticketDetailsData: null,
  loaded: false,
  activeTerminalLayout: null,
  terminalMouseCursorEnabled: true,
  allBettingDisabled: false,
  isFreeBetMode: false,
};
