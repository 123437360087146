import {
  each,
  concat,
  merge,
  omit,
} from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_STREAM_TOKEN](state, token) {
    state.streamConfig.token = token;
  },
  [types.SET_CONFIG](state, payload) {
    state.config = merge(state.config, omit(payload, ['translations', 'rules', 'taxes']));
    state.config.translations = payload.translations;
  },
  [types.SET_GAME_STATE](state, gameState) {
    if (gameState.eventId) {
      state.gameState = {};
      state.gameState = gameState;
      state.config.state = gameState;
    }
  },
  [types.SET_PLAYER](state, player) {
    state.player = player;
  },
  [types.RESET_FORECAST](state) {
    state.forecastOdds = {};
  },
  [types.SET_OFFER](state, offer) {
    state.offer = [];
    state.offer = offer;
    each(state.offer, (round) => {
      each(round.odds.winner, (roundOdd) => {
        roundOdd.betTypeId = 0; // eslint-disable-line
        roundOdd.round = round.eventIdToday; // eslint-disable-line
      });
      each(round.odds.show, (roundOdd) => {
        roundOdd.betTypeId = 1; // eslint-disable-line
        roundOdd.round = round.eventIdToday; // eslint-disable-line
      });
      each(round.odds.place, (roundOdd) => {
        roundOdd.betTypeId = 2; // eslint-disable-line
        roundOdd.round = round.eventIdToday; // eslint-disable-line
      });
      each(round.odds.tricast, (roundOdd) => {
        roundOdd.betTypeId = 11; // eslint-disable-line
        roundOdd.round = round.eventIdToday; // eslint-disable-line
      });
    });
  },
  [types.SET_H2H_COMBS](state) {
    const numberOfRacers = state.config.numberOfContestants.value;
    const racers = [];
    for (let j = 1; j <= numberOfRacers; j += 1) {
      racers.push(j);
    }
    const combs = [];
    for (let i = 0; i < racers.length - 1; i += 1) {
      for (let j = i + 1; j < racers.length; j += 1) {
        combs.push([racers[i], racers[j]]);
      }
    }
    state.headToHeadCombinations = combs;
  },
  [types.CREATE_FORECAST_SCHEMA](state) {
    const racers = state.config.numberOfContestants.value;
    for (let i = 1; i <= racers; i += 1) {
      state.forecastOdds[i] = {};
      for (let j = 1; j <= racers; j += 1) {
        state.forecastOdds[i][j] = {
          racers: j,
          place: i,
          empty: true,
        };
      }
    }
    for (let i = 1; i <= racers; i += 1) {
      state.reverseForecastOdds[i] = {};
      for (let j = 1; j <= racers; j += 1) {
        state.reverseForecastOdds[i][j] = {
          racers: j,
          place: i,
          empty: true,
        };
      }
    }
  },
  [types.PARSE_FORECAST_ODDS](state, odds) {
    each(odds, (odd) => {
      state.forecastOdds[odd.racers[1]][odd.racers[0]] = {
        racers: odd.racers,
        place: odd.racers[1],
        value: odd.value,
        displayId: `${odd.racers[0]}${odd.racers[1]}`,
        reverseDisplayId: `${odd.racers[1]}${odd.racers[0]}`,
        round: state.offerCurrentRound,
        reverse: false,
        betTypeId: 10,
      };
    });
  },
  [types.PARSE_REVERSE_FORECAST_ODDS](state, odds) {
    each(odds, (odd) => {
      state.reverseForecastOdds[odd.racers[1]][odd.racers[0]] = {
        racers: odd.racers,
        place: odd.racers[1],
        value: odd.value,
        displayId: `${odd.racers[0]}${odd.racers[1]}`,
        reverseDisplayId: `${odd.racers[1]}${odd.racers[0]}`,
        round: state.offerCurrentRound,
        reverse: true,
        betTypeId: 12,
      };
    });
  },
  [types.SET_CURRENT_ROUND](state, round) {
    state.offerCurrentRound = round;
  },
  [types.PARSE_RACECARD_ODDS](state, odds) {
    const raceCardOdds = odds;
    each(raceCardOdds.winner, (odd) => {
      odd.betTypeId = 0; // eslint-disable-line
      if (String(odd.racers[0] === 'undefined')) {
        odd.racerMarket = String(odd.racers); // eslint-disable-line
      } else {
        odd.racerMarket = String(odd.racers[0]); // eslint-disable-line
      }
    });
    each(raceCardOdds.show, (odd) => {
      odd.betTypeId = 1; // eslint-disable-line
      if (String(odd.racers[0] === 'undefined')) {
        odd.racerMarket = String(odd.racers); // eslint-disable-line
      } else {
        odd.racerMarket = String(odd.racers[0]); // eslint-disable-line
      }
    });
    each(raceCardOdds.place, (odd) => {
      odd.betTypeId = 2; // eslint-disable-line
      if (String(odd.racers[0] === 'undefined')) {
        odd.racerMarket = String(odd.racers); // eslint-disable-line
      } else {
        odd.racerMarket = String(odd.racers[0]); // eslint-disable-line
      }
    });
    state.raceCardOdds = concat(
      raceCardOdds.winner,
      raceCardOdds.place,
      raceCardOdds.show,
    ).filter((odd) => !!odd);
    each(state.raceCardOdds, (oddItem, keyId) => {
      if (oddItem) {
        const odd = oddItem;
        odd.keyId = keyId;
      }
    });
  },
  [types.PARSE_HEADTOHEAD_ODDS](state, odds) {
    state.headToHeadOdds = [];
    const combinations = state.headToHeadCombinations;
    const races = {};
    for (let i = 0; i < odds.length; i += 1) {
      const { racers } = odds[i];
      for (let j = 0; j < combinations.length; j += 1) {
        if (racers[0] === combinations[j][0] && racers[1] === combinations[j][1]) {
          races[j] = {};
          races[j]['0'] = {
            racers: odds[i].racers,
            value: odds[i].value,
            column: 1,
            uniqueKey: `L${j}`,
            round: state.offerCurrentRound,
            betTypeId: 14,
          };
        } else if (racers[0] === combinations[j][1] && racers[1] === combinations[j][0]) {
          races[j]['1'] = {
            racers: odds[i].racers,
            value: odds[i].value,
            column: 2,
            uniqueKey: `R${j}`,
            round: state.offerCurrentRound,
            betTypeId: 14,
          };
        }
      }
    }
    state.headToHeadOdds = races;
  },
  [types.STOP_BETTING](state, roundId) {
    state.disabledRound = roundId;
  },
  [types.SET_PAYIN_PROGRESS](state, payinInProgress) {
    state.isPayinInProgress = payinInProgress;
  },
  [types.SET_EXPIRED_BETS](state, value) {
    state.expiredBets = value;
  },
  [types.SET_DISABLED_ROUND_BY_SOCKET](state, value) {
    state.disabledRoundBySocket = value;
  },
  [types.SET_USER_UUID](state, userUuid) {
    state.userUuid = userUuid;
  },
  [types.SET_IS_MOBILE](state, isMobile) {
    state.isMobile = isMobile;
  },
  [types.SET_JACKPOT_STATE](state, jackpotState) {
    state.jackpotState = jackpotState;
  },
  [types.SET_SET_STATE_MESSAGE](state, data) {
    state.setStateData = data;
  },
  [types.TRANSFER_STATE_MESSAGE](state, data) {
    state.isStateMesageTrasfered = data;
  },
  [types.SET_IS_REVERSE_FORECAST_ACTIVE](state, isActive) {
    state.reverseForecastActive = isActive;
  },
  [types.SET_IS_TERMINAL](state, payload) {
    state.isTerminal = payload;
  },
  [types.SET_NEXT_ROUND_TIME](state, time) {
    state.nextRoundTime = time;
  },
  [types.SET_IN_PROGRESS_ROUND](state, payload) {
    state.inProgressRound = payload;
  },
  [types.SET_TERMINAL_USER_DATA](state, payload) {
    state.terminalUserData = payload;
  },
  [types.SET_TERMINAL](state, payload) {
    state.isTerminal = payload;
  },
  [types.SET_CURRENT_SCHEDULE_ROUND](state, round) {
    state.currentScheduleRound = round;
  },
  [types.SET_TICKET_HISTORY_TERMINAL](state, payload) {
    state.ticketHistoryTerminal = payload.message;
  },
  [types.SET_TICKET_DETAILS_DATA](state, payload) {
    state.ticketDetailsData = payload;
  },
  [types.SET_LOADED](state, data) {
    state.loaded = data;
  },
  [types.SET_ACTIVE_TERMINAL_LAYOUT](state, layout) {
    state.activeTerminalLayout = layout;
  },
  [types.SET_TERMINAL_MOUSE_CURSOR_ENABLED](state, isEnabled) {
    state.terminalMouseCursorEnabled = isEnabled;
  },
  [types.DISABLE_ALL_BETTING](state, isDisabled) {
    state.allBettingDisabled = isDisabled;
  },
};
