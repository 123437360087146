import {
  map,
  omit,
  each,
  merge,
  //  upperFirst,
  //  capitalize,
} from 'lodash';
import { auth } from '@nsftx/games-sdk-js';
import api from '../api/index';
import mutationTypes from './mutationTypes';

export default {
  async getStreamToken({ commit, getters }, data) {
    const results = await api.getStreamToken(getters.config, data);
    commit(mutationTypes.SET_STREAM_TOKEN, results.token);
  },
  setConfig({ commit }, payload) {
    commit(mutationTypes.SET_CONFIG, payload);
  },
  setState({ commit }, gameState) {
    commit(mutationTypes.SET_GAME_STATE, gameState);
  },
  setOffer({ commit }, offer) {
    commit(mutationTypes.SET_OFFER, offer);
  },
  setHeadToHeadCombs({ commit }, data) {
    commit(mutationTypes.SET_H2H_COMBS, data);
  },
  stopBetting({ commit }, roundId) {
    commit(mutationTypes.STOP_BETTING, roundId);
  },
  parseOdds({ dispatch }, odds) {
    dispatch('parseRaceCardOdds', odds);
    dispatch('parseForecastOdds', odds.forecast);
    dispatch('parseReverseForecastOdds', odds.forecast);
    if (odds.headToHead) {
      dispatch('parseHeadToHeadOdds', odds.headToHead);
      dispatch('setHeadToHeadCombs');
    }
  },
  setCurrentRound({ commit }, round) {
    commit(mutationTypes.SET_CURRENT_ROUND, round);
  },
  parseRaceCardOdds({ commit }, odds) {
    commit(mutationTypes.PARSE_RACECARD_ODDS, odds);
  },
  parseHeadToHeadOdds({ commit }, odds) {
    commit(mutationTypes.PARSE_HEADTOHEAD_ODDS, odds);
  },
  parseForecastOdds({ commit, dispatch }, odds) {
    dispatch('createForecastSchema');
    commit(mutationTypes.PARSE_FORECAST_ODDS, odds);
  },
  parseReverseForecastOdds({ commit }, odds) {
    commit(mutationTypes.PARSE_REVERSE_FORECAST_ODDS, odds);
  },
  createForecastSchema({ commit, dispatch }) {
    dispatch('resetForecast');
    commit(mutationTypes.CREATE_FORECAST_SCHEMA);
  },
  resetForecast({ commit }) {
    commit(mutationTypes.RESET_FORECAST);
  },
  payTheTicket({ dispatch }, payload) {
    dispatch('betslip/ticketPayin', payload);
  },
  setUserBalance({ dispatch }, payload) {
    dispatch('user/setUserBalance', payload, { root: true });
  },
  setUserData({ dispatch }, payload) {
    let user = {};
    if (payload.auth) {
      user = {
        auth: omit(payload.auth, 'user'),
        profile: payload.auth.user,
      };
    } else {
      user = {
        auth: {
          token: payload.token,
          tpToken: payload.tpToken,
        },
        profile: omit(payload, ['token', 'tokenType']),
      };
    }
    dispatch('user/updateUser', user, { root: true });
  },
  formatPlayerTickets({ getters }, payload) {
    const text = getters.translations.general_racer;
    const formatTricastCombinations = (combinations) => {
      const placements = {
        first: new Set(),
        second: new Set(),
        third: new Set(),
      };
      combinations.forEach((combination) => {
        const [first, second, third] = combination.split('-');
        placements.first.add(first);
        if (second) placements.second.add(second);
        if (third) placements.third.add(third);
      });
      const firstStr = Array.from(placements.first).sort().join(', ');
      const secondStr = Array.from(placements.second).sort().join(', ');
      const thirdStr = Array.from(placements.third).sort().join(', ');
      return `${firstStr} - ${secondStr} - ${thirdStr}`;
    };
    const getValue = (bet) => {
      let betValue = '';
      const betComs = [];
      if (bet.type === 12 || bet.type === 10) {
        betValue = `${bet.value[0]}-${bet.value[1]}`;
      } else if (bet.type === 14) {
        betValue = `${text} ${bet.value[0]}`;
      } else if (bet.type === 11 || bet.type === 13) {
        if (bet.combinations && bet.combinations.length > 1) {
          each(bet.combinations, (comb) => {
            betComs.push(comb.value);
          });
          formatTricastCombinations(betComs);
        }
        betValue = bet.combinations.length > 1
          ? String(formatTricastCombinations(betComs)) : bet.combinations[0].value;
      } else {
        betValue = `${text} ${bet.value}`;
      }
      return betValue;
    };
    const getMarket = (bet) => {
      let betMarket = '';
      if (bet.type === 11 && bet.combinations.length > 1) {
        betMarket = `${bet.bet.title} (${bet.combinations.length} ${getters.translations.general_combinations})`;
      } else if (bet.type === 13 && bet.combinations.length > 1) {
        betMarket = `${bet.bet.title} (${bet.combinations.length} ${getters.translations.general_combinations})`;
      } else if (bet.type === 14) {
        if (bet.outcome.value[0] < bet.outcome.value[1]) {
          betMarket = `${getters.translations.general_racer} ${bet.outcome.value[0]} - ${getters.translations.general_racer} ${bet.outcome.value[1]}`;
        } else {
          betMarket = `${getters.translations.general_racer} ${bet.outcome.value[1]} - ${getters.translations.general_racer} ${bet.outcome.value[0]}`;
        }
      } else {
        betMarket = bet.bet.title;
      }
      return betMarket;
    };
    const getOdd = (bet) => {
      let oddValue = null;
      if (bet.type === 10) {
        oddValue = bet.combinations[0].odd.toFixed(2);
      } else if (bet.type === 11 && bet.combinations.length === 1) {
        oddValue = bet.combinations[0].odd;
      } else if (bet.type === 13) {
        oddValue = 0;
      } else {
        oddValue = bet.odd.toFixed(2);
      }
      return Number(oddValue);
    };
    return map(payload, (tickets) => {
      let bets = [];
      bets = map(tickets.bets, (bet) => ({
        id: bet.id,
        status: bet.status,
        market: getMarket(bet),
        round: bet.eventId,
        odd: getOdd(bet),
        outcome: getValue(bet),
        stake: bet.amount,
        eventValue: bet.eventValue,
        winnings: bet.winnings,
        type: bet.type,
      }));
      return {
        id: tickets.id,
        payout: tickets.payout,
        payin: tickets.payin,
        payinTax: tickets.payinTax,
        payoutTax: tickets.payoutTax,
        superBonus: tickets.superBonus,
        createdAt: tickets.createdAt,
        status: tickets.status,
        productName: tickets.product,
        maxPossibleWin: tickets.maxPossibleWin,
        bets,
      };
    });
  },
  updateTicketValues({ dispatch, getters }) {
    if (getters['gamesBetslip/ticket'].length) {
      getters['gamesBetslip/ticket'].forEach((bet) => {
        if (bet.roundNumber === getters.offer[0].eventIdToday) {
          dispatch('gamesBetslip/updateBetValue', ({
            ...bet,
            locked: true,
            permanentlyLock: true,
          }));
          dispatch('setExpiredBets', true);
        }
      });
    } else {
      dispatch('setExpiredBets', false);
    }
  },
  setPayinProgress({ commit }, payinInProgress) {
    commit(mutationTypes.SET_PAYIN_PROGRESS, payinInProgress);
  },
  setExpiredBets({ commit }, value) {
    commit(mutationTypes.SET_EXPIRED_BETS, value);
  },
  setDisabledRoundBySocket({ commit }, value) {
    commit(mutationTypes.SET_DISABLED_ROUND_BY_SOCKET, value);
  },
  setUserUuid({ commit }, userUuid) {
    commit(mutationTypes.SET_USER_UUID, userUuid);
  },
  setIsMobile({ commit }, isMobile) {
    commit(mutationTypes.SET_IS_MOBILE, isMobile);
  },
  async setJackpotState({ commit, getters }) {
    const jackpot = await api.getJackpot(getters.config);
    if (jackpot.jackpotId) {
      commit(mutationTypes.SET_JACKPOT_STATE, jackpot);
    }
  },
  setStateMessage({ commit }, data) {
    commit(mutationTypes.SET_SET_STATE_MESSAGE, data);
  },
  transferSetStateMessage({ commit }, data) {
    commit(mutationTypes.TRANSFER_STATE_MESSAGE, data);
  },
  setIsReverseForecastActive({ commit }, isActive) {
    commit(mutationTypes.SET_IS_REVERSE_FORECAST_ACTIVE, isActive);
  },
  setIsTerminal({ commit }, isTerminal) {
    commit(mutationTypes.SET_IS_TERMINAL, isTerminal);
  },
  setNextRoundTime({ commit }, time) {
    commit(mutationTypes.SET_NEXT_ROUND_TIME, time);
  },
  setInProgressRound({ commit }, payload) {
    commit(mutationTypes.SET_IN_PROGRESS_ROUND, payload);
  },
  setTerminalUserData({ commit }, payload) {
    commit(mutationTypes.SET_TERMINAL_USER_DATA, payload);
  },
  setTerminal({ commit }, payload) {
    commit(mutationTypes.SET_TERMINAL, payload);
  },
  setCurrentScheduleRound({ commit }, round) {
    commit(mutationTypes.SET_CURRENT_SCHEDULE_ROUND, round);
  },
  setTicketHistoryTerminal({ commit }, payload) {
    commit(mutationTypes.SET_TICKET_HISTORY_TERMINAL, payload);
  },
  setTicketDetailsData({ commit }, payload) {
    commit(mutationTypes.SET_TICKET_DETAILS_DATA, payload);
  },
  setLoaded({ commit }, data) {
    commit(mutationTypes.SET_LOADED, data);
  },
  setActiveTerminalLayout({ commit }, layout) {
    commit(mutationTypes.SET_ACTIVE_TERMINAL_LAYOUT, layout);
  },
  setTerminalMouseCursorEnabled({ commit }, isEnabled) {
    commit(mutationTypes.SET_TERMINAL_MOUSE_CURSOR_ENABLED, isEnabled);
  },
  disableAllBetting({ commit }, isDisabled) {
    commit(mutationTypes.DISABLE_ALL_BETTING, isDisabled);
  },
  async getPlayer({ getters, dispatch }, { token }) {
    const { config } = getters;
    try {
      const player = await auth.authenticate(config, token);
      const isLoggedIn = !!player.uuid;
      const userData = {
        auth: {
          token: player.auth.token,
          tpToken: player.auth.thirdPartyToken || null,
        },
        profile: merge({
          balance: player.balance,
          balanceTotal: player.balance,
          logged: isLoggedIn,
          id: player.uuid,
        }),
      };
      if (isLoggedIn) {
        dispatch('user/updateUser', userData);
        dispatch('setUserBalance', player.balance);
      }
    } catch (error) {
      const message = {
        message: getters.translations.general_player_login,
        notificationTimeout: 7,
      };
      dispatch('notifications/setNotification', message);
    }
  },
};
